import {Layout, LoginGate} from '@/components/shared'
import {ThemeProvider} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from '../theme'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import * as Sentry from "@sentry/react";
import {FirebaseProvider} from "../providers/FirebaseProvider";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer, toast} from 'react-toastify';

Sentry.init({
    dsn: "https://239eb34dbe664172b9dd6d16493c34e2@o1256520.ingest.sentry.io/6432147",
    integrations: []
});

const App = ({Component, pageProps}) => {
    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <CssBaseline/>
                <FirebaseProvider>
                        <LoginGate>
                            <Layout>
                                <Component {...pageProps} />
                            </Layout>
                        </LoginGate>
                    <ToastContainer/>
                </FirebaseProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    )
}

export default App