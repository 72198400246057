import React, {useMemo} from 'react';
import { FirebaseContext } from 'src/contexts/FirebaseContext';
import {initializeApp} from "firebase/app";
import {getDatabase} from "firebase/database";

interface FirebaseProviderProviderParams {

}

const firebaseConfig = {

    apiKey: "AIzaSyAJSiy5_e5KLI6S5aJ3OhxReuVCehBqiYo",
    authDomain: "app-jackiej-co.firebaseapp.com",
    databaseURL: "https://app-jackiej-co-default-rtdb.firebaseio.com",
    projectId: "app-jackiej-co",
    storageBucket: "app-jackiej-co.appspot.com",
    messagingSenderId: "783620283384",
    appId: "1:783620283384:web:a4c67d239743e8fb8a940d"

};

export const FirebaseProvider: React.FC<FirebaseProviderProviderParams> = ({children}) => {

    const firebaseApp = useMemo(() => initializeApp(firebaseConfig), [])
    const database = useMemo( () => getDatabase(firebaseApp), [firebaseApp])

    return (
        <FirebaseContext.Provider
            value={{
                firebaseApp,
                database
            }}>
            {children}
        </FirebaseContext.Provider>
    );
};
